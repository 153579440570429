//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ProductModel } from '@/modules/product/product-model';
import { ProductPermissions } from '@/modules/product/product-permissions';
import TableViewPage from '@/shared/components/table-view-page.vue';

const { fields } = ProductModel;

export default {
  name: 'app-product-list-table',
  props: ['rows'],

  components: {
    [TableViewPage.name]: TableViewPage,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'entities.product.fields.id',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.product.fields.itemName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'barcode',
          field: 'barcode',
          label: 'entities.product.fields.barcode',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // rows: 'product/list/rows',
      // count: 'product/list/count',
      // pagination: 'product/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'product/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'product/destroy/loading',
    }),

    hasPermissionToEdit() {
      return new ProductPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ProductPermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'product/list/doChangeSort',
      doChangePaginationCurrentPage: 'product/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'product/list/doChangePaginationPageSize',
      doMountTable: 'product/list/doMountTable',
      doDestroy: 'product/destroy/doDestroy',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },

    // presenter(row, fieldName) {
    //   return ProductModel.presenter(row, fieldName);
    // },

    async doDestroyWithConfirm(id) {
      try {
        // await this.$confirm(
        //   i18n('common.areYouSure'),
        //   i18n('common.confirm'),
        //   {
        //     confirmButtonText: i18n('common.yes'),
        //     cancelButtonText: i18n('common.no'),
        //     type: 'warning',
        //   },
        // );

        await this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
