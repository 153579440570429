import { render, staticRenderFns } from "./product-form-modal.vue?vue&type=template&id=26535df2&scoped=true&"
import script from "./product-form-modal.vue?vue&type=script&lang=js&"
export * from "./product-form-modal.vue?vue&type=script&lang=js&"
import style0 from "./product-form-modal.vue?vue&type=style&index=0&id=26535df2&lang=scss&scoped=true&"
import style1 from "./product-form-modal.vue?vue&type=style&index=1&id=26535df2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26535df2",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSpinnerIos})
